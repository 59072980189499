import React from 'react';
import FileUploader from './components/FileUploader';
import './App.sass';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='appTitle'>FINANCIAL STATEMENTS ANALYZER</div>
      </header>
      <FileUploader />
    </div>
  );
}

export default App;
