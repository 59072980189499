import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { addFiles, removeFile, uploadFilesAsync } from '../redux/fileUploaderSlice';
import pdfLogo from '../assets/pfd_logo.png';
import './FileUploader.sass';
import { Puff } from 'react-loader-spinner';

const FileUploader = () => {
  const files = useSelector((state) => state.fileUploader.files);
  const dispatch = useDispatch();
  const generatedText = useSelector((state) => state.fileUploader.generatedText);
  const uploadStatus = useSelector((state) => state.fileUploader.uploadStatus);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, application/pdf', // Accept PDF files
    onDrop: acceptedFiles => {
      dispatch(addFiles(acceptedFiles.map(file => ({
        file, // Keep a reference to the original File object
        name: file.name,
        preview: URL.createObjectURL(file)
      }))));
    }
  });

  const truncateFileName = (name) => {
    if (name.length > 40) {
      return name.substring(0, 10) + '...' + name.substring(name.length - 10, name.length);
    }
    return name;
  }

  const thumbs = [
    <div key="label" className="label"></div>,
    ...files.map(file => (
      <div className="thumb" key={file.name}>
        <img
          src={file.name.toLowerCase().endsWith('.pdf') ? pdfLogo : file.preview} // Conditionally render the image source
          className="img"
          alt="preview"
        />
        <div className="fileName">{truncateFileName(file.name)}</div>
        <button className="removeButton" onClick={() => dispatch(removeFile(file))}>x</button>
      </div>
    ))
  ];

  // Extract the HTML code
  const codeMatch = generatedText.match(/<!DOCTYPE html>[\s\S]*<\/html>/);
  const code = codeMatch ? codeMatch[0] : '';

  // Create a new blob object and URL, memoized with useMemo
  const url = React.useMemo(() => {
    const blob = new Blob([code], { type: 'text/html' });
    return URL.createObjectURL(blob);
  }, [code]);

  const GeneratedText = React.memo(({ text, url }) => {
    // Split the text at the HTML code
    const [beforeCode, afterCode] = text.split(/<!DOCTYPE html>[\s\S]*<\/html>/);
  
    return (
      <div className="generatedTextContainer">
        <p className="generatedText">{beforeCode}</p>
        <div className="iframeContainer">
          {code && <iframe src={url} title="Chart" />}
        </div>
        <p className="generatedText">{afterCode}</p>
      </div>
    );
  });   

  // Effect to revoke object URL
  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const LoadingMessage = () => {
    const [counter, setCounter] = useState(0);
    const messages = [
      "Analyzing your Financial Statements...",
      "Hold tight, this might take a few moments...",
      "Still working...",
    ];

    useEffect(() => {
      const timer = setInterval(() => {
        setCounter((prevCounter) => (prevCounter + 1) % messages.length);
      }, 8000);
      return () => clearInterval(timer);
    }, [messages.length]);

    return <p>{messages[counter]}</p>;
  };

  return (
    <div className="container">
      <div className="uploadContainer">
        <div className="dropzoneContainer">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
        </div>
        <div className="divider" />
        {thumbs.length > 1 ?
          <div className="thumbsContainer">
            {thumbs}
          </div> :
          <div className='selectLabelContainer'>
            <div className='selectLabel'> No files selected. Please start by uploading your files </div>
          </div>
        }
      </div>
      {
        uploadStatus === 'loading' ?
          <div className="loadingScreen">
            <Puff
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
            />
            <LoadingMessage />
          </div> : <button
            className="submitButton"
            onClick={() => {
              console.log('uploading files');
              dispatch(uploadFilesAsync(files))}}
            //disable the button on conditon there is no files selected
            disabled={files.length === 0}
            >Submit</button>
      }
      {generatedText ? <GeneratedText text={generatedText} url={url} /> : null}
    </div>
  );
}

export default FileUploader;
